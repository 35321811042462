import React, { useState } from 'react';

import * as link from '../../utils/helper/link-config';
import * as bintu from '../../utils/bintu/api-requests';
import * as ls from '../../utils/helper/ls-vars';

import { makeStyles } from '@mui/styles';
import { Box, Button, Checkbox, FormControlLabel, Grid, InputAdornment, Link, Stack, TextField, Typography } from '@mui/material';
import { ArrowRightAlt, LockOutlined, MailOutline, WorkOutline } from '@mui/icons-material';
import SnackbarMessage from '../global/SnackbarMessage';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    header: {
        color: theme.palette.common.white
    },
    accent: {
        color: theme.palette.primary.main,
        fontWeight: 700
    },
    underline: {
        color: theme.palette.common.white,
        fontWeight: 400
    },
    textField: {
        '& .NanoInputBase-root': {
            color: theme.palette.common.white,
        }
    }
}));

export default function SignUp(props) {
    const classes = useStyles();
    const navigate = useNavigate();
    let mailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const [checkedTerms, setCheckedTerms] = useState(false);
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});
    const [textfields, setTextfields] = useState({
        email: {
            value: "",
            id: "email",
            autoComplete: "email",
            label: "E-Mail",
            type: "text",
            error: false,
            icon: <MailOutline color="primary" />
        },
        organisation: {
            value: "",
            id: "organisation",
            autoComplete: "organisation",
            label: "Company name",
            type: "text",
            error: false,
            icon: <WorkOutline color="primary" />
        },
        password: {
            value: "",
            id: "password",
            autoComplete: "new-password",
            label: "Password",
            type: "password",
            error: false,
            icon: <LockOutlined color="primary" />
        },
        confirmPassword: {
            value: "",
            id: "confirmPassword",
            autoComplete: "new-password",
            label: "Confirm Password",
            type: "password",
            error: false,
            icon: <LockOutlined color="primary" />
        }
    });

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowError(false);
    }

    const handleUpdateTextfield = (id) => (event) => {
        let updatedValue = event.target.value
        setTextfields({
            ...textfields,
            [id]: {
                ...textfields[id],
                value: updatedValue
            }
        })
    }

    const handleSubmitTextfield = (event) => {
        event.preventDefault();
        handleSignUp(event);
    }

    const handleAnyKeyPressed = (event) => {
        let code = event.charCode
        switch (code) {
            case 13: handleSignUp(event); break;
            default: break;
        }
    }

    const credentialsAreInvalid = () => {
        let mailInvalid = !mailRegex.test(textfields.email.value)
        let passwordInvalid = textfields.password.value === "" || textfields.confirmPassword.value !== textfields.password.value;
        let organisationInvalid = textfields.organisation.value === ""

        setTextfields({
            ...textfields,
            email: {
                ...textfields.email,
                error: mailInvalid
            },
            password: {
                ...textfields.password,
                error: passwordInvalid
            },
            confirmPassword: {
                ...textfields.password,
                error: passwordInvalid
            },
            organisation: {
                ...textfields.organisation,
                error: organisationInvalid
            }
        });

        if (!checkedTerms) {
            setError({ type: "info", message: "To use the nanoStream Cloud dashboard, you need to accept our terms and conditions." });
            setShowError(true);
        }

        return mailInvalid || passwordInvalid || organisationInvalid || !checkedTerms;
    }

    // Google Ads Tracking Code
    const reportGTagConversion = (url) => {
        var callback = function () {
            if (typeof (url) != 'undefined') {
                window.location = url;
            }
        };
        window.gtag('event', 'conversion', {
            'send_to': 'AW-1071822910/bNrLCK_FpPgCEL7wiv8D',
            'event_callback': callback
        });
    }

    const sendAnalyticsEvent = () => {
        window.ga('send', {
            hitType: 'event',
            eventCategory: 'bintu_sign_up',
            eventAction: 'bintu_sign_up',
            eventLabel: 'Bintu Sign Up'
        });
    }



    const handleSignUp = (event) => {

        if (credentialsAreInvalid()) return;

        // TO DO SIGN UP USER
        let userCredentials = {
            email: textfields.email.value,
            password: textfields.password.value,
            organisation: textfields.organisation.value
        }

        bintu.createOrganisation(userCredentials)
            .then((response) => {
                if (response.success) {
                    navigate(link.VERIFY)
                }
            }).catch((error) => {
                if (!error.success) {
                    setError(error);
                    setShowError(true);
                }
            })
    }

    const handleAcceptTerms = () => {
        if (!checkedTerms && showError) {
            setShowError(false);
        }
        setCheckedTerms(!checkedTerms);
    }

    return (
        <Grid container direction="row" alignItems="center" justifyContent="center">
            <SnackbarMessage
                open={showError}
                close={handleCloseError}
                type={error.type ? error.type : "error"}
            >
                <b>{error.type ? "" : "Error:"} {error.code}</b> {error.message}
            </SnackbarMessage>
            <Grid item container xs={12}>
                <Grid item xs={12}>
                    <form autoComplete>
                        {
                            Object.values(textfields).map((textfield, i) => (
                                <Box key={`${textfield.id}-${i}-signup`} component="div" sx={{ my: 1 }}>
                                    <TextField
                                        key={`${textfield.id}-${i}-signup`}
                                        focused
                                        sx={{ input: { color: `#fff` } }}
                                        fullWidth
                                        className={classes.textField}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    {textfield.icon}
                                                </InputAdornment>
                                            )
                                        }}
                                        variant="standard"
                                        type={textfield.type}
                                        label={textfield.label}
                                        autoComplete={textfield.autoComplete}
                                        error={textfields[textfield.id].error}
                                        onKeyPress={handleAnyKeyPressed}
                                        onChange={handleUpdateTextfield(Object.keys(textfields)[i])}
                                        onSubmit={handleSubmitTextfield}
                                    />
                                </Box>
                            ))
                        }
                    </form>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        className={classes.checkboxLabel}
                        control={<Checkbox sx={{ color: '#fff' }} color="primary" name="acceptTerms" />}
                        label={
                            <Typography variant="overline" color="white">
                                Accept <Link underline="hover" href={link.IMPRINT} target="_blank">Terms and Conditions</Link>
                            </Typography>
                        }
                        checked={checkedTerms}
                        onChange={handleAcceptTerms}
                    />
                </Grid>
                <Grid item xs={12} mt={1}>
                    <Stack direction="row" justifyContent={"space-between"}>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            onClick={handleSignUp}
                            endIcon={<ArrowRightAlt />}
                        >
                            Sign Up
                        </Button>
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={props.signin}
                        >
                            Login
                        </Button>
                    </Stack>
                </Grid>

            </Grid>
        </Grid>

    )
}
