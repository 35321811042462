import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import * as link from '../utils/helper/link-config';
import * as m from '../utils/helper/methods';

import world from '../assets/world-background.jpeg';
import datagrid from '../assets/datagrid_background.jpeg';
import nsc_orange from '../assets/orange-nsc.png';

import { makeStyles } from '@mui/styles';
import { Grid, Typography, alpha, Collapse, IconButton, Button, Box, Stack, Chip, Hidden, Divider, Alert } from '@mui/material';
import { useTheme } from '@emotion/react';

import SignIn from '../components/registration/SignIn';
import Loading from '../components/global/Loading';
import SignUp from '../components/registration/SignUp';
import SnackbarMessage from '../components/global/SnackbarMessage';
import { ArrowRight, ArrowRightAlt, CalendarMonthOutlined, CreditCardOff, CreditCardOffOutlined, SwitchAccessShortcutAdd } from '@mui/icons-material';
import InviteToken from '../components/registration/InviteToken';
import { verifyEmail } from '../utils/bintu/api-requests';


export default function VerifyEmail(props) {
    const theme = useTheme();
    const { code } = useParams();
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    document.title = "Verify Email | nanoStream Cloud Dashboard";

    const verify = () => {
        setLoading(true);
        setTimeout(() => {
            verifyEmail({ code })
                .then((response) => {
                    navigate(link.WELCOME);
                }).catch((error) => {
                    setLoading(false);
                    setError(error);
                    console.error(error);
                });
        }, 500)
    }

    const handleClickedSocialMediaLink = (link) => () => window.open(link);

    useEffect(() => {
        if (m.CHECK_ACCESS()) { navigate(link.DASHBOARD) }
    }, [])

    return (
        <Fragment>
            <Grid container p={2}
                sx={{
                    minHeight: '100vh',
                    height: '100%', maxHeight: '100%',
                    bgcolor: theme.palette.secondary.light,
                    // backgroundImage: `linear-gradient(to bottom, ${alpha(theme.palette.secondary.light, 0.4)}, ${theme.palette.secondary.main})`,
                    backgroundImage: // theme.palette.mode === "light"
                        // ? `linear-gradient(to top, ${alpha(theme.palette.secondary.light, 0.6)}, ${alpha(theme.palette.secondary.light, 0.9)}), url(${world})` : 
                        `linear-gradient(to top, ${alpha(theme.palette.secondary.light, 0.8)}, ${alpha(theme.palette.secondary.dark, 0.9)}), url(${world})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "top",
                }}
            >
                <Grid item container xs={12} md={12} px={2} justifyContent="center" alignItems="center">
                    <Grid item xs={12} sm={8} md={6} lg={5}
                        sx={{
                            color: theme.palette.common.white,
                            textAlign: 'center', borderRadius: theme.spacing(2),
                            p: 4, border: `2px solid ${theme.palette.info.main}`,
                            backgroundImage: `linear-gradient(to bottom, ${alpha(theme.palette.secondary.light, 0.4)}, ${theme.palette.secondary.main})`,
                            boxShadow: theme.shadows[23]
                        }}
                    >
                        <Box
                            component="img" src={nsc_orange} mb={2}
                            sx={{ height: { xs: 50, md: 90 } }}
                        />
                        <Typography variant="h4" mb={1} sx={{ lineHeight: 1, fontWeight: 700 }}>
                            Your Verification Code
                        </Typography>
                        <Stack direction="row" spacing={1} justifyContent={"center"} sx={{ mt: 2 }}>
                            {
                                code.split("").map((ch) => (
                                    <Box key={ch}
                                        sx={[{
                                            py: 1, px: 1.5,
                                            borderRadius: 2,
                                            backgroundColor: alpha(theme.palette.common.white, 0.1)
                                        }, error && {
                                            color: theme.palette.error.main, border: 1,
                                            borderColor: theme.palette.error.main
                                        }]}
                                    >
                                        {ch}
                                    </Box>
                                ))
                            }
                        </Stack>
                        <Grid item xs={12} sx={{ mt: 2 }}>
                            {
                                loading
                                    ? <Loading />
                                    : <Fragment>
                                        {
                                            error
                                                ? <Alert severity='error' variant="filled">
                                                    {error?.message}
                                                </Alert>
                                                : <Button
                                                    endIcon={<ArrowRightAlt />}
                                                    variant="contained"
                                                    onClick={verify}
                                                >
                                                    Verify and Proceed
                                                </Button>
                                        }
                                    </Fragment>
                            }
                        </Grid>
                        <Typography variant="body1" color="primary" sx={{ fontWeight: 700, mt: 3 }}>
                            Get in touch with us!
                        </Typography>
                        <Stack direction="row" flexWrap={"wrap"} justifyContent={"center"}>
                            {
                                link.SOCIAL_MEDIA.map((s, i) => (
                                    <IconButton key={i}
                                        color="primary" size="medium"
                                        onClick={handleClickedSocialMediaLink(s.link)}
                                    >
                                        {s.icon}
                                    </IconButton>
                                ))
                            }
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
        </Fragment >
    )
}
